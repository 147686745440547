import { useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import useAuth from "../hooks/useAuth";
import { firebaseConfig } from "../utils/apis";

// ----------------------------------------------------------------------

export default function FirebaseInit() {
  const { isAuthenticated, saveToken, initialize } = useAuth();
  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);
    getPerformance(app);
  }, [isAuthenticated, saveToken, initialize]);

  return null;
}

// routes
import Router from "./routes";
// theme
import ThemeConfig from "./theme";
// components
import { AxiosInterceptor } from "./utils/axios";
import ScrollToTop from "./components/ScrollToTop";
import FirebaseInit from "./components/FirebaseInit";
import SessionTimeout from "./components/SessionTimeout";
import ThemePrimaryColor from "./components/ThemePrimaryColor";
import NotistackProvider from "./components/NotistackProvider";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <NotistackProvider>
          <AxiosInterceptor>
            <FirebaseInit />
            <SessionTimeout />
            <ScrollToTop />
            <Router />
          </AxiosInterceptor>
        </NotistackProvider>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}

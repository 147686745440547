import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// components
import LoadingScreen from "../components/LoadingScreen";
import AuthGuard from "../guards/AuthGuard";
import { frontendPages } from "./paths";

// ----------------------------------------------------------------------
// eslint-disable-next-line
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = !frontendPages.includes(pathname);
  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: (
        <AuthGuard>
          {" "}
          <DashboardLayout />{" "}
        </AuthGuard>
      ),
      children: [
        { path: "", element: <Dashboard /> },
        {
          path: "account",
          children: [
            { path: "", element: <Account /> },
            { path: ":tab", element: <Account /> },
          ],
        },
        { path: "payment-methods", element: <PaymentMethods /> },
        { path: "cards", element: <Cards /> },
        { path: "documents", element: <Documents /> },
        { path: "contact-us", element: <ContactUs /> },
        { path: "about-us", element: <AboutUs /> },
        {
          path: "loans",
          children: [
            { path: "", element: <Loans /> },
            { path: ":loanId", element: <Loan /> },
            { path: ":loanId/:status", element: <Loan /> },
            { path: "add", element: <AddLoan /> },
          ],
        },
      ],
    },
    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "500", element: <ErrorOccured /> },
        { path: "*", element: <Navigate to="/404" replace /> },
        {
          path: "login",
          element: (
            <AuthGuard>
              <Login />
            </AuthGuard>
          ),
        },
        {
          path: "signup",
          children: [
            {
              path: "",
              element: (
                <AuthGuard>
                  <Signup />
                </AuthGuard>
              ),
            },
            {
              path: "account",
              element: (
                <AuthGuard>
                  <SignupAccount />
                </AuthGuard>
              ),
            },
          ],
        },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        {
          path: "verify-email",
          element: (
            <AuthGuard>
              <VerifyEmail />
            </AuthGuard>
          ),
        },
        {
          path: "verify-phone",
          element: (
            <AuthGuard>
              <VerifyPhone />
            </AuthGuard>
          ),
        },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// admin pages
const Dashboard = Loadable(lazy(() => import("../pages/dashboard/Dashboard")));
const Account = Loadable(lazy(() => import("../pages/dashboard/Account")));
const AddLoan = Loadable(lazy(() => import("../pages/dashboard/AddLoan")));
const Signup = Loadable(lazy(() => import("../pages/authentication/Signup")));
const SignupAccount = Loadable(
  lazy(() => import("../pages/authentication/SignupAccount"))
);
const PaymentMethods = Loadable(
  lazy(() => import("../pages/dashboard/PaymentMethods"))
);
const Cards = Loadable(lazy(() => import("../pages/dashboard/Cards")));
const ContactUs = Loadable(lazy(() => import("../pages/dashboard/ContactUs")));
const AboutUs = Loadable(lazy(() => import("../pages/dashboard/AboutUs")));
const Loans = Loadable(lazy(() => import("../pages/dashboard/Loans")));
const Loan = Loadable(lazy(() => import("../pages/dashboard/Loan")));
const Documents = Loadable(lazy(() => import("../pages/dashboard/Documents")));
const ErrorOccured = Loadable(
  lazy(() => import("../pages/errors/ErrorOccured"))
);
const NotFound = Loadable(lazy(() => import("../pages/errors/NotFound")));

// auth
const VerifyEmail = Loadable(
  lazy(() => import("../pages/authentication/VerifyEmail"))
);
const VerifyPhone = Loadable(
  lazy(() => import("../pages/authentication/VerifyPhone"))
);
const ForgotPassword = Loadable(
  lazy(() => import("../pages/authentication/ForgotPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("../pages/authentication/ResetPassword"))
);
const Login = Loadable(lazy(() => import("../pages/authentication/Login")));

// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/";
const ROOTS_DASHBOARD = "/";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  Login: path(ROOTS_AUTH, "login"),
  signup: path(ROOTS_AUTH, "signup"),
  signupAccount: path(ROOTS_AUTH, "signup/account"),
  welcome: path(ROOTS_AUTH, "welcome"),
  forgotPassword: path(ROOTS_AUTH, "forgot-password"),
  resetPassword: path(ROOTS_AUTH, "reset-password"),
  verifyEmail: path(ROOTS_AUTH, "verify-email"),
  verifyPhone: path(ROOTS_AUTH, "verify-phone"),
  setPassword: path(ROOTS_AUTH, "set-password"),
};

export const PATH_PAGE = {
  page404: "404",
  errorOccured: "500",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    root: path(ROOTS_DASHBOARD, ""),
    account: path(ROOTS_DASHBOARD, "account"),
    employment: path(ROOTS_DASHBOARD, "account/employment"),
    password: path(ROOTS_DASHBOARD, "account/password"),
    paymentMethods: path(ROOTS_DASHBOARD, "payment-methods"),
    cards: path(ROOTS_DASHBOARD, "cards"),
    documents: path(ROOTS_DASHBOARD, "documents"),
    loans: path(ROOTS_DASHBOARD, "loans"),
    addLoan: path(ROOTS_DASHBOARD, "loans/add"),
    contactUs: path(ROOTS_DASHBOARD, "contact-us"),
    aboutUs: path(ROOTS_DASHBOARD, "about-us"),
  },
  misc: {
    refer: path(ROOTS_DASHBOARD, "refer"),
  },
};

export const frontendPages = [
  "/login",
  "/signup",
  "/signup/account",
  "/forgot-password",
  "/reset-password",
  "/404",
];

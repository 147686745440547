// components
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from "@mui/icons-material/Info";
import AddCardIcon from "@mui/icons-material/AddCard";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import HistoryIcon from "@mui/icons-material/History";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "",
    items: [
      {
        title: "Home",
        path: PATH_DASHBOARD.general.root,
        icon: <HomeIcon />,
      },
    ],
  },
  {
    subheader: "",
    items: [
      {
        title: "My Loans",
        path: PATH_DASHBOARD.general.loans,
        icon: <HistoryIcon />,
      },
    ],
  },
  {
    subheader: "settings",
    items: [
      {
        title: "My Account",
        path: PATH_DASHBOARD.general.account,
        icon: <AccountCircleIcon />,
      },
      {
        title: "Payment Methods",
        path: PATH_DASHBOARD.general.paymentMethods,
        icon: <AccountBalanceWalletIcon />,
      },
      {
        title: "Repayment Cards",
        path: PATH_DASHBOARD.general.cards,
        icon: <AddCardIcon />,
      },
      {
        title: "Documents",
        path: PATH_DASHBOARD.general.documents,
        icon: <DocumentScannerIcon />,
      },
    ],
  },
  {
    subheader: "others",
    items: [
      {
        title: "Contact Us",
        path: PATH_DASHBOARD.general.contactUs,
        icon: <HelpIcon />,
      },
      {
        title: "About Us",
        path: PATH_DASHBOARD.general.aboutUs,
        icon: <InfoIcon />,
      },
    ],
  },
];

export default sidebarConfig;

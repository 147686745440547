import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Stack, TextField, InputAdornment, IconButton, Button } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import useAuth from '../hooks/useAuth';


export default function PopupLogin() {
    const { login, logout } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [open, setOpen] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    const handleLogin = async () => {
        try {
            if (!password || !email) {
                throw (new Error("Enter a valid password to proceed!"));
            }
            setLoading(true);
            await login(email, password);
            enqueueSnackbar('Logged in successfully!', { variant: 'success' });
            setOpen(false);
        } catch (err) {
            enqueueSnackbar(err.message, { variant: 'error' });
        }
        setLoading(false);
    }

    useEffect(() => {
        const getPopupName = window.localStorage.getItem('popupName') || "";
        const getPopupEmail = window.localStorage.getItem('popupEmail') || "";
        if (getPopupName && getPopupEmail) {
            setOpen(true);
            setName(getPopupName);
            setEmail(getPopupEmail);
        } else {
            setOpen(false);
            setName("");
            setEmail("");
        }

    }, []);

    return (
        <Dialog maxWidth="xs" open={open}>
            <DialogTitle sx={{ color: 'error.main' }}>You have been logged out, {name}!</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter your password below to proceed.
                </DialogContentText>
                <TextField
                    fullWidth
                    value={password}
                    autoComplete="current-password"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    label="Password"
                    sx={{ mt: 5 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    onChange={(e) => { setPassword(e.target.value) }}
                />
            </DialogContent>
            <DialogActions>
                <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} sx={{ width: '100%' }}>
                    <LoadingButton fullWidth loading={loading} onClick={handleLogin} color="primary" variant="contained">
                        Login
                    </LoadingButton>
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} sx={{ width: '100%' }}>
                        <Typography variant="subtitle2">Not {name}?</Typography>
                        <Button onClick={() => { logout() }}>Logout</Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}
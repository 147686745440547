import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
// slices
import dashboardReducer from "./slices/dashboard";
import referReducer from "./slices/refer";
import savingsReducer from "./slices/savings";
import investmentsReducer from "./slices/investments";
import walletReducer from "./slices/wallet";

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  refer: referReducer,
  savings: savingsReducer,
  investments: investmentsReducer,
  wallet: walletReducer,
});

export { rootPersistConfig, rootReducer };

import axios from "axios";
import { useEffect } from "react";
import useAuth from "../hooks/useAuth";

const axiosInstance = axios.create();
const LOGOUT_STATUSES = ["userid_expired", "userid_error", "invalid_token"];

const AxiosInterceptor = ({ children }) => {
  const { isAuthenticated, logout } = useAuth();

  useEffect(() => {
    const resInterceptor = (response) => {
      const status =
        response && response.data && response.data.status
          ? response.data.status
          : "";
      if (isAuthenticated && LOGOUT_STATUSES.includes(status)) {
        logout();
      }
      return response;
    };

    const errInterceptor = (error) =>
      Promise.resolve(
        error.response && error.response.data ? error.response : {}
      );

    const interceptor = axiosInstance.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    return () => axiosInstance.interceptors.response.eject(interceptor);
  }, [isAuthenticated, logout]);
  return children;
};

export default axiosInstance;
export { AxiosInterceptor };

import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
import { API_PATH } from "../../utils/apis";

const initialState = {
  isLoading: false,
  error: false,
  transactions: [],
  summary: {},
  sortBy: null,
  filters: {
    method: "",
  },
};

const slice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getSuccess(state, action) {
      state.isLoading = false;
      state.transactions = action.payload.data;
    },
    //  SORT & FILTER POSTS
    sortBy(state, action) {
      state.sortBy = action.payload;
    },
    filter(state, action) {
      state.filters.method = action.payload.method;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { filter, sortBy } = slice.actions;

export function getTransactions() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(API_PATH.getAllTransactions);
      const { status, data } = response.data;
      if (status === "success") {
        dispatch(slice.actions.getSuccess({ data }));
      } else {
        dispatch(
          slice.actions.hasError(
            new Error(
              "We cannot fetch your transactions at the moment! Please check back later."
            )
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addTopup() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(API_PATH.getAllTransactions);
      const { status, data } = response.data;
      if (status === "success") {
        dispatch(slice.actions.getSuccess({ data }));
      } else {
        dispatch(
          slice.actions.hasError(
            new Error(
              "We cannot fetch your transactions at the moment! Please check back later."
            )
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function closeTopup() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(API_PATH.getAllTransactions);
      const { status, data } = response.data;
      if (status === "success") {
        dispatch(slice.actions.getSuccess({ data }));
      } else {
        dispatch(
          slice.actions.hasError(
            new Error(
              "We cannot fetch your transactions at the moment! Please check back later."
            )
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
